<template>
  <div
    v-if="song"
    class="page-with-title"
  >
    <page-title>
      <h1>Aprovação: {{ song.musica_titulo }}</h1>
    </page-title>
    <div class="p-4">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label>Título</label>
            <input
              v-model="song.musica_titulo"
              type="text"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-3">
          <div class="d-flex align-items-center">
            <div v-if="song.id_artista" class="mr-2">
              <img width="40" :src="song.artist.avatar" />
            </div>
            <div>
              <label>
                <span>Artista </span>
                <span v-if="!song.id_artista">(NOVO)</span>
                <small v-if="song.id_artista">
                  <router-link
                    target="_blank"
                    :to="{ name: 'artistShow', params: {id: song.artist.cod_artista} }"
                  >(Ver no painel)</router-link>
                </small>
              </label>
              <auto-complete
                :data="song.artista"
                @selected="changeArtist"
              />
              <div v-if="song.id_artista">
                <small v-if="song.artist.genre">Gênero: {{ song.artist.genre.nome }}</small>
                <small v-else><i>Sem gênero</i></small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Compositores</label>
            <input
              v-model="song.compositores"
              type="text"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Código do Vídeo</label>
            <input
              v-model="song.cod_video"
              type="text"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-7">
              <label>Letra</label>
              <textarea
                v-model="song.letra"
                class="form-control"
                rows="15"
              />
            </div>
            <div class="col-auto">
              <label>Músicas similares</label>
              <ul v-if="similarSongs.length">
                <li v-for="song in similarSongs" :key="song.id_musica">
                  <router-link
                  :to="{ name: 'songShow', params: {id: song.id_musica} }"
                    target="_blank"
                  >{{ song.titulo }} - {{ song.artista }}</router-link>
                </li>
              </ul>
              <div v-else>
                <i>Sem músicas similares</i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 mb-3">
        <button
          class="btn btn-2 btn-bigger mr-3"
          @click="approve"
        >
          <b><i class="fa fa-check" /> Aprovar nova música</b>
        </button>
        <button
          class="btn btn-link text-danger"
          @click="disapprove()"
        >
          <b><i class="fa fa-close" /> Recusar nova música</b>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    searchSongs,
    getContributionSong,
    approveContributionSong,
    disapproveContributionSong
  } from '@/api/songs'
  import PageTitle from '@/components/titles/PageTitle'
    import AutoComplete from '@/components/utils/AutoComplete'

  export default {
    components: { PageTitle, AutoComplete },
    data: () => ({
      song: null,
      similarSongs: []
    }),
    async mounted () {
      await this.getSong()
      await this.getSimilarSongs()
    },
    methods: {
      async getSong () {
        const res = await getContributionSong(this.$route.params.id)
        this.song = res.data
      },
      async getSimilarSongs () {
        this.similarSongs = await searchSongs(this.song.musica_titulo + ' ' + this.song.artist.artista)
        console.debug(this.similarSongs)
      },
      changeArtist (artist) {
        this.song.artista = artist.artista
        this.song.id_artista = artist.id_artista
      },
      async approve () {
        const approvedData = await approveContributionSong(this.song.id, this.song)
        
        if (approvedData.is_artist_created) {
          const routeData = this.$router.resolve({name: 'artistShow', params: {id: approvedData.artist.cod_artista}})
          window.open(routeData.href, '_blank');
        }

        this.$router.push({ name: 'songsContributionIndex' })
      },
      async disapprove () {
        await disapproveContributionSong(this.song.id)
        this.$router.push({ name: 'songsContributionIndex' })
      }
    }
  }
</script>